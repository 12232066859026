import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IconDefinition,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-context-slider',
  templateUrl: './context-slider.component.html',
})
export class ContextSliderComponent implements OnInit, OnDestroy {
  eventFee: string;
  meetingPoint = false;
  parking: string;
  @Input() sidebar: boolean;
  @Input() sideNavRef: MatSidenav;
  @Input() drawerOpened: boolean;
  open = true;
  visible = false;
  meetingLocation = 'meeting-same';
  parkingLocation = 'parking-same';
  entryFee = 'free';
  rsvpStartDate = 'current-date';
  rsvpEndDate = 'same-huddle-date';
  isTransitionEnd = false;
  faAngleLeft: IconDefinition = faAngleLeft;
  faAngleRight: IconDefinition = faAngleRight;
  @Output() paidChange = new EventEmitter<string>();
  @Output() meetingChaange = new EventEmitter<string>();
  @Output() parkingChange = new EventEmitter<string>();
  @Output() contextOpen = new EventEmitter<boolean>();
  @Output() rsvpDateChange = new EventEmitter<{
    value: string;
    field: string;
  }>();
  @Output() coHostChange = new EventEmitter();
  @Input() events: Observable<any>;
  private subscriptionRef: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.events)
      this.subscriptionRef = this.events.subscribe((data) => {
        this.visible = data;
      });
  }

  ngOnDestroy(): void {
    this.subscriptionRef?.unsubscribe();
  }

  onRadioChange(value: any) {
    this.paidChange.emit(value);
  }

  onMeetingPointChange(value: any) {
    this.meetingChaange.emit(value);
  }

  onParkingAddrressChange(value: any) {
    this.parkingChange.emit(value);
  }

  onRSVPChange(value: any, field: string) {
    this.rsvpDateChange.emit({ value, field });
  }

  onCoHostChanged(event: any) {
    this.coHostChange.emit(event);
    this.visible = false;
  }

  onContextMenu() {
    this.sideNavRef.toggle();
  }

  transitionEnd(e: Event) {
    this.isTransitionEnd = !this.open;
  }

  showDialog() {
    this.visible = true;
  }
}
