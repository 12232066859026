<div class="surface-ground">
  <app-sidebar
    (drawerOpened)="drawerOpenChanged($event)"
    [label]="'Advance Filters'"
    layoutStyleClass="w-[93.3333%]"
    styleClass="w-auto lg:w-[342px]"
  >
    <div
      class="ng-mat-drawer-side mb-7 mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"
    >
      <div class="col-span-6 md:col-span-6">
        <p-button
          label="Reset all filters"
          styleClass="primary"
          [text]="true"
          (click)="resetAllFilters()"
        ></p-button>
      </div>
      <div class="col-span-6 md:col-span-6">
        <label for="meeting-point" class="form-title mb-1 block px-2 leading-6"
          >Timeline
        </label>
        <div class="flex flex-col gap-3 px-2">
          <div class="field-checkbox relative">
            <p-radioButton
              inputId="date_range"
              name="date_range"
              Upcoming
              Huddles
              value="CUSTOM_DATE"
              [(ngModel)]="filterHuddle"
              (ngModelChange)="
                onChangeFilters($event, 'TIMELINE', 'CUSTOM_DATE')
              "
            ></p-radioButton>
            <label for="date_range" class="ml-2">Date Range</label>
            <p-calendar
              class="max-w-[200px]"
              [(ngModel)]="filterByDate"
              [inline]="true"
              selectionMode="range"
              [readonlyInput]="true"
              dateFormat="yy-mm-dd"
              (ngModelChange)="
                onChangeFilters($event, 'TIMELINE', 'CUSTOM_DATE')
              "
              styleClass="huddle-datepicker"
            >
            </p-calendar>
            <div
              *ngIf="filterHuddle !== 'CUSTOM_DATE'"
              [ngClass]="isDark ? 'opacity-[0.05]' : 'opacity-60'"
              class="calendar-overlay absolute top-[34px] h-[90%] w-full rounded-md"
            ></div>
          </div>
          <div class="field-checkbox">
            <p-radioButton
              inputId="upcoming"
              name="filter_huddle"
              Upcoming
              Huddles
              value="UPCOMING"
              (ngModelChange)="onChangeFilters($event, 'TIMELINE', 'UPCOMING')"
              [(ngModel)]="filterHuddle"
            ></p-radioButton>
            <label for="upcoming" class="ml-2">Upcoming Huddles</label>
          </div>
          <div class="field-checkbox">
            <p-radioButton
              inputId="past"
              name="filter_huddle"
              Upcoming
              Huddles
              value="PAST"
              (ngModelChange)="onChangeFilters($event, 'TIMELINE', 'PAST')"
              [(ngModel)]="filterHuddle"
            ></p-radioButton>
            <label for="past" class="ml-2">Past Huddles</label>
          </div>
          <div class="field-checkbox">
            <p-radioButton
              inputId="no_date"
              name="filter_huddle"
              Upcoming
              Huddles
              value="NO_DATE"
              (ngModelChange)="onChangeFilters($event, 'TIMELINE', 'NO_DATE')"
              [(ngModel)]="filterHuddle"
            ></p-radioButton>
            <label for="no_date" class="ml-2">Not Scheduled</label>
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-6">
        <label for="meeting-point" class="form-title mb-1 block px-2 leading-6"
          >Activity Type
        </label>
        <div class="flex flex-col gap-3 px-2">
          <div *ngFor="let activity of activityType" class="field-checkbox">
            <p-radioButton
              [inputId]="activity.code"
              name="activity_type"
              [value]="activity"
              (ngModelChange)="onChangeFilters($event, 'ACTIVITY_TYPE')"
              [(ngModel)]="defaultActivityType"
            ></p-radioButton>
            <label [for]="activity.code" class="ml-2">{{
              activity.name
            }}</label>
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-6">
        <label for="meeting-point" class="form-title mb-1 block px-2 leading-6"
          >Participation Type
        </label>
        <div class="flex flex-col gap-3 px-2">
          <div
            *ngFor="let participation of participationType"
            class="field-checkbox"
          >
            <p-radioButton
              [inputId]="participation.code"
              name="participation"
              [value]="participation"
              (ngModelChange)="onChangeFilters($event, 'PARTICIPATION_TYPE')"
              [(ngModel)]="defaultParticipationType"
            ></p-radioButton>
            <label [for]="participation.code" class="ml-2">{{
              participation.name
            }}</label>
          </div>
        </div>
      </div>
    </div>

    <div
      class="ng-mat-drawer-content mx-auto h-full w-[720px] transition-all duration-300"
      [style]="
        !sidebarEvent.drawerOpen && !sidebarEvent.isMobileScreen
          ? 'transform:translate(25%, 1px)'
          : ''
      "
    >
      <div class="linear max mx-auto mb-24 transition-all duration-500">
        <div class="mb-8 mt-2 px-2">
          <div class="mb-2 flex justify-end">
            <button
              type="button"
              (click)="onClickCreate()"
              class="mp-button mp-button-outlined mb-4 truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
            >
              <span>Create</span>
            </button>
          </div>
          <div class="mx-auto max-w-[720px]">
            <div
              class="search-results"
              infinite-scroll
              [infiniteScrollDistance]="1"
              [infiniteScrollUpDistance]="2"
              [infiniteScrollThrottle]="500"
              [infiniteScrollContainer]="selector"
              (scrolled)="onScrolledDown()"
              [fromRoot]="true"
            >
              <div class="mb-8 last:mb-0" *ngFor="let date of groupDate">
                <h2 class="h-level-2 mb-3 border-b pb-2">
                  {{ getKey(date) }}
                </h2>
                <div *ngIf="getValue(date)">
                  <div
                    class="shipping-item shrink grow basis-0 p-[4px]"
                    *ngFor="let huddle of $any(getValue(date))"
                  >
                    <p-card
                      class="mx-auto block md:mx-0 md:my-auto"
                      (click)="openHuddle($event, huddle.id)"
                      styleClass="cursor-pointer transition duration-300"
                    >
                      <div class="grid grid-cols-1 gap-x-2 sm:grid-cols-6">
                        <div class="col-span-6 md:col-span-6">
                          <div
                            class="relative -ml-[1.25rem] -mr-[1.25rem] -mt-[2.5rem] h-52 rounded-md bg-stone-400 pt-[56.25%]"
                          >
                            <div
                              class="absolute bottom-0 left-0 right-0 top-0 rounded-md"
                            >
                              <img
                                *ngIf="huddle.coverPhotoUrl"
                                class="h-full w-full rounded-md"
                                [src]="
                                  getCoverPhotoUrl(huddle.id) | image | async
                                "
                                alt="cropped image"
                              />
                              <img
                                *ngIf="!huddle.coverPhotoUrl"
                                class="h-full w-full rounded-md object-cover"
                                [src]="
                                  huddle.activity !== 'HIKING'
                                    ? './assets/images/huddle-default-cover.svg'
                                    : './assets/images/huddle-default-cover-hiking.svg'
                                "
                                alt="cropped image"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-span-6 mb-2 mt-2 md:col-span-6">
                          <span
                            *ngIf="huddle.startDateTime"
                            class="primary uppercase"
                          >
                            {{
                              formatDate(
                                huddle.startDateTime,
                                huddle.endDateTime
                              )
                            }}
                          </span>
                          <span
                            *ngIf="!huddle.startDateTime"
                            class="primary uppercase"
                            >No Scheduled Date</span
                          >
                        </div>

                        <div class="col-span-6 md:col-span-6">
                          <h1 class="h-level-1">
                            {{ huddle.name }}
                          </h1>
                        </div>
                        <div
                          class="col-span-6 md:col-span-6"
                          *ngIf="huddle.communityId"
                        >
                          <h2 class="h-level-2">
                            {{ huddle.communityObj.name }}
                          </h2>
                        </div>
                        <div class="col-span-6 my-2 md:col-span-6">
                          <h2 class="h-level-2 gray-400">
                            {{
                              huddle.venue?.name
                                ? huddle.venue.name
                                : "No Location Selected"
                            }}
                          </h2>
                          <h3 class="h-level-2 gray-400">
                            {{ huddle.venue?.formattedAddress }}
                          </h3>
                        </div>
                        <div class="flex items-center">
                          <span>
                            <span>{{ activityMap[huddle.activity] }}</span>
                          </span>
                          <span class="h-level-2">&nbsp;&#8901;&nbsp;</span>
                          <span>
                            <span>{{ visibilityMap[huddle.privacy] }}</span>
                          </span>
                        </div>
                        <div class="col-span-6 md:col-span-6">
                          <div class="flex items-center">
                            <p-button
                              [label]="
                                count[huddle.id].totalInterested + ' Interested'
                              "
                              styleClass="primary"
                              [text]="true"
                            ></p-button>
                            <span class="h-level-2">&nbsp;&#8901;&nbsp;</span>
                            <p-button
                              [label]="
                                count[huddle.id].totalTentative + ' Going'
                              "
                              styleClass="primary"
                              [text]="true"
                            ></p-button>
                            <span
                              class="h-level-2"
                              *ngIf="
                                huddle?.selfHuddler?.rsvpType === 'YES' ||
                                huddle?.selfHuddler?.rsvpType === 'MAYBE'
                              "
                            >
                              &nbsp;&#8901;&nbsp;
                            </span>

                            <p-button
                              [label]="
                                huddle?.selfHuddler?.rsvpType === 'YES'
                                  ? 'You are confirmed '
                                  : huddle?.selfHuddler?.rsvpType === 'MAYBE'
                                    ? 'You are tentative '
                                    : ''
                              "
                              styleClass="primary"
                              [text]="true"
                            ></p-button>
                            <span
                              class="h-level-2"
                              *ngIf="huddle.selfHuddler?.guestCount > 0"
                            >
                              &nbsp;
                            </span>
                            <p-button
                              *ngIf="huddle.selfHuddler?.guestCount > 0"
                              [label]="
                                ' with ' +
                                huddle.selfHuddler?.guestCount +
                                ' guests'
                              "
                              styleClass="primary"
                              [text]="true"
                            ></p-button>
                          </div>
                        </div>
                        <div class="col-span-6 md:col-span-6">
                          <div
                            class="flex flex-row flex-wrap justify-end gap-4"
                          >
                            <div>
                              <button
                                *ngIf="
                                  huddle?.selfHuddler?.rsvpType !== 'YES' &&
                                  huddle?.selfHuddler?.rsvpType !== 'MAYBE'
                                "
                                [disabled]="isPastHuddle(huddle)"
                                pButton
                                type="button"
                                (click)="
                                  saveInterest(
                                    !huddle.selfHuddler?.interested,
                                    huddle.id
                                  );
                                  $event.stopPropagation()
                                "
                                [ngClass]="
                                  huddle?.selfHuddler?.interested
                                    ? ''
                                    : 'hover:bg-teal-500'
                                "
                                class="mp-button mp-button-outlined group h-12 w-auto justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
                              >
                                <span
                                  [ngClass]="
                                    !huddle?.selfHuddler?.interested
                                      ? 'hidden group-hover:block'
                                      : 'block'
                                  "
                                  class="material-icons prevent"
                                >
                                  star
                                </span>
                                <span
                                  class="material-icons prevent"
                                  [ngClass]="
                                    !huddle?.selfHuddler?.interested
                                      ? 'block group-hover:hidden'
                                      : 'hidden'
                                  "
                                >
                                  star_outline
                                </span>
                                <span class="prevent hidden sm:block"
                                  >Interested</span
                                >
                              </button>
                            </div>
                            <div class="md:flex-0 flex">
                              <button
                                pButton
                                [disabled]="isPastHuddle(huddle)"
                                (click)="navigateToHuddleView(huddle.id)"
                                type="button"
                                [ngClass]="
                                  huddle.selfHuddler &&
                                  huddle.selfHuddler.rsvpType === 'YES'
                                    ? ''
                                    : 'hover:bg-teal-500'
                                "
                                class="mp-button mp-button-outlined prevent group h-12 w-full justify-center gap-2 px-5 py-2 leading-6 transition-all md:w-auto"
                              >
                                <!-- Check icon when RSVP is YES -->
                                <span
                                  [ngClass]="
                                    !huddle.selfHuddler ||
                                    huddle.selfHuddler.rsvpType !== 'YES'
                                      ? 'hidden group-hover:block'
                                      : 'enabled block'
                                  "
                                  class="material-icons prevent"
                                >
                                  check_circle
                                </span>

                                <!-- Outline check icon when RSVP is not YES -->
                                <span
                                  class="material-icons prevent"
                                  [ngClass]="
                                    !huddle.selfHuddler ||
                                    huddle.selfHuddler.rsvpType !== 'YES'
                                      ? 'block group-hover:hidden'
                                      : 'hidden'
                                  "
                                >
                                  check_circle_outline
                                </span>

                                <!-- Button text based on RSVP status -->
                                <span class="prevent">
                                  {{
                                    huddle.selfHuddler &&
                                    (huddle.selfHuddler.rsvpType === "YES" ||
                                      huddle.selfHuddler.rsvpType === "MAYBE")
                                      ? "Edit RSVP"
                                      : "RSVP Now"
                                  }}
                                </span>
                              </button>

                              <!-- <button
                                pButton
                                type="button"
                                (click)="toggle($event, huddle, 'GOING')"
                                class="prevent mp-button mp-button-outlined h-12 w-4/12 justify-center rounded-bl-none rounded-tl-none border-l-0 px-2 py-2 leading-6 transition-all md:w-auto"
                              >
                                <span class="material-icons prevent">
                                  expand_more
                                </span>
                              </button> -->
                            </div>
                            <div class="hidden">
                              <button
                                pButton
                                type="button"
                                class="prevent mp-button mp-button-outlined h-12 w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
                              >
                                <span class="material-icons prevent">mail</span>
                                <span class="prevent hidden xl:block"
                                  >Invite</span
                                >
                              </button>
                            </div>
                            <div class="">
                              <button
                                pButton
                                (click)="toggle($event, huddle, 'MORE')"
                                type="button"
                                class="prevent mp-button mp-button-outlined h-12 w-full items-baseline justify-center rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
                              >
                                ...
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-template pTemplate="footer">
                        <h2 class="h-level-2 border-t pt-2">Recommendation</h2>
                        <p class="sub-title">
                          We do not sufficient data to provide recommendation at
                          this time.
                        </p>
                      </ng-template>
                    </p-card>
                  </div>
                </div>
                <div
                  *ngIf="!$any(getValue(date))?.length"
                  class="mt-2 flex items-center gap-3"
                >
                  <span class="material-icons">calendar_today</span>
                  <span class="">No matches found.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-sidebar>
</div>
<p-menu
  #going
  [model]="goingOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
  (onHide)="huddleId = null"
>
  <ng-template pTemplate="item" let-item>
    <a
      (click)="saveRsvp(item.label === 'Tentative' ? 'MAYBE' : 'NO')"
      class="p-menuitem-link p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div class="flex items-center">
        <span
          class="material-icons"
          [ngClass]="
            (rsvpType === 'MAYBE' && item.label === 'Tentative') ||
            (rsvpType === 'NO' && item.label !== 'Tentative')
              ? 'primary'
              : ''
          "
        >
          {{
            item.label === "Tentative" ? "do_not_disturb_on" : "cancel"
          }}</span
        >
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<p-menu
  #menu
  [model]="moreOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      *ngIf="
        ['copy_huddle_link', 'add_to_calendar', 'report_huddle'].includes(
          item.id
        )
      "
      [ngClass]="{
        'pointer-events-none opacity-40': item.disabled,
        'block md:hidden': item?.hideInMobile,
      }"
      class="p-menuitem-link justify-content-between align-items-center flex p-3"
      (click)="onOverlayMenuClick(item.id)"
    >
      <div>
        <span [class]="item.icon">
          <fa-icon
            [icon]="item.icon"
            size="1x"
            styleClass="highlight-text"
          ></fa-icon>
        </span>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>

    <!-- <a
      *ngIf="item.id === 'cancel'"
      [ngClass]="{
        'pointer-events-none opacity-40': userInfo.id !== host?.id,
      }"
      class="p-menuitem-link justify-content-between align-items-center flex p-3"
      (click)="onOverlayMenuClick(item.id)"
    >
      <div>
        <span [class]="item.icon">
          <fa-icon
            [icon]="item.icon"
            size="1x"
            styleClass="highlight-text"
          ></fa-icon>
        </span>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a> -->
  </ng-template>
</p-menu>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<p-confirmDialog #rsvp styleClass="md:w-5/12 w-10/12" appendTo="body">
  <ng-template pTemplate="header">
    <h3 class="h-level-3">Cancel Huddle</h3>
  </ng-template>
  <ng-template pTemplate="message">
    <p class="mt-2">Do you want to cancel the huddle?</p>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="mt-2 flex flex-col justify-end gap-4 xl:flex-row">
      <button
        pButton
        (click)="rsvp.reject()"
        class="mp-button mp-button-outlined justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Cancel
      </button>
      <button
        pButton
        (click)="cancelHuddle()"
        class="mp-button mp-button-filled justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Ok
      </button>
    </div>
  </ng-template>
</p-confirmDialog>

<app-sharelink
  *ngIf="huddleId"
  [event]="shareLinkEvent.asObservable()"
  objectType="HUDDLE"
  [objectId]="huddleId"
></app-sharelink>

<app-invite-dialog
  [(visible)]="inviteVisible"
  buttonLabel="Invite"
  (buttonClick)="invite($event)"
></app-invite-dialog>
