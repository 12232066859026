import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IconDefinition,
  faAngleDown,
  faCar,
  faStar,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Observable, Subject, tap } from 'rxjs';
import { ImagePipe } from 'src/app/main/pipes/image.pipe';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { VehicleService } from 'src/app/main/services/vehicle.service';
import { AppState } from 'src/app/main/store/model/state.model';
import {
  Carpool,
  DialogEvent,
  Huddle,
  HuddleDetailResponse,
  HuddlePrimaryResponse,
  HuddleSettings,
  TripType,
  User,
  UserDetails,
  Vehicle,
} from 'src/app/main/types/main.types';
import { ActivityEnum, formatEventDate, initMap } from 'src/app/main/utilities';
import {
  imageCompression,
  imageConst,
  validateImage,
} from 'src/app/main/utilities/imageHelper';
import {
  cropperMenuOptions,
  goingOptions,
  huddleMoreOptions,
  invitedOptions,
  requestedOptions,
} from 'src/app/main/utilities/overlayHelper';
import { environment } from 'src/environments/environment';

enum TripTypeEnum {
  R = 'ROUND',
  D = 'DEPART',
  RT = 'RETURN',
}

@Component({
  selector: 'app-huddle-view',
  templateUrl: './huddle-view.component.html',
})
export class HuddleViewComponent implements OnInit {
  carpoolVisible = false;
  coverPhotoUrl: string | null = null;
  submitted = false;
  carpoolFormGroup!: FormGroup;
  faUsers: IconDefinition = faUsers;
  faUser: IconDefinition = faUser;
  faCar: IconDefinition = faCar;
  faStar: IconDefinition = faStar;
  faAngleDown: IconDefinition = faAngleDown;
  tabs: MenuItem[] = [];
  activeTab: MenuItem | undefined;
  eventDate: number;
  eventLongDate: Date;
  eventLongDateEnd: Date | null;
  huddleView: Huddle;
  huddlePrimaryResponse: HuddlePrimaryResponse;
  huddleDetailResponse: HuddleDetailResponse;
  isRoundOrDepart = false;
  isReturn = false;
  vehicles: Vehicle[] = [];
  tripType: TripType[];
  startDate: Date = new Date();
  huddleId: string | null;
  carpools: any;
  startAddressSubject: Subject<any> = new Subject<any>();
  endAddressSubject: Subject<any> = new Subject<any>();
  items: MenuItem[] | undefined;
  isCreate = true;
  isViewMode = false;
  isPastHuddle = false;
  isHostOrCohost = false;
  isHost = false;
  moreOptions: any = huddleMoreOptions;
  goingOptions: any = goingOptions;
  invitedOptions: any = invitedOptions;
  leaderName: string;
  profilePic: string;
  userDetail$: Observable<UserDetails>;
  showInterestBtn = true;
  interested = false;
  rsvpType = '';
  prevRsvpType = '';
  userInfo: UserDetails;
  canCreateCarpool: boolean;
  isLoading = true;
  isUploading = false;
  isRemoving = false;
  inviteVisible = false;
  requestedOptions = requestedOptions;
  activityEnum = ActivityEnum;
  menuId: string;
  prevTab: MenuItem | undefined;
  selectedButtonTab: string;
  isCarpoolAllowed = false;
  expenseTracEnabled = false;
  potluckPlanningEnabled = false;
  guestVisible = false;
  selectedButtonGuests: any[];
  selectedGuest: any;
  adult = 0;
  teens = 0;
  kids = 0;
  toddlers = 0;
  seniors = 0;
  settings: HuddleSettings;
  huddleViewEvent: Subject<Huddle> = new Subject<Huddle>();
  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();
  viewCarpoolEvent: Subject<any> = new Subject<any>();
  huddleParticipantEvent: Subject<string> = new Subject<string>();
  shareLinkEvent: Subject<boolean> = new Subject<boolean>();
  displayHostRsvpUpdateConfirmation = false;
  settingDetails: any = {};
  userPickerVisible = false;
  userInvitePickerVisible = false;
  userPickerEvent: Subject<any> = new Subject<any>();
  selectedUser: any[] = [];
  @ViewChild('rsvp', { static: false }) rsvpDialog: ConfirmDialog;

  scale = 1;
  isDragging = false;
  lastMouseY = 0;
  isDraggingStart = false;
  translateY = 0;
  file: File;
  isCroppedCover = false;
  roundCropper = false;
  ratio: number = 16 / 9;
  imageChangedEvent: any;
  visible: boolean;
  isCoverUploaded = false;
  imageCroppedEvent: ImageCroppedEvent;
  croppedImage: any = null;
  isProfileCropppedCover = false;
  blob: Blob | null | undefined;
  uploadType = 1;
  transform: ImageTransform = {};
  isMobile: boolean;
  editable = false;
  storeCoverPhotoUrl: string | null = null;
  @ViewChild('upload') uploadRef: ElementRef;
  @ViewChild('image') imageRef: ElementRef;
  cropperMenuOptions = cropperMenuOptions;
  eventStartDateTime: Date | null;
  eventEndDateTime: Date | null;
  eventRsvpStartDateTime: Date | null;
  eventRsvpEndDateTime: Date | null;
  followings: User[] = [];
  constructor(
    private huddleService: HuddleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private vehicleService: VehicleService,
    private store: Store<AppState>,
    private confirmationService: ConfirmationService,
    private imageCompress: NgxImageCompressService,
    private sanitizer: DomSanitizer,
    private imagePipe: ImagePipe,
  ) {
    this.selectedButtonGuests = [
      { label: 'Confirm', value: 'YES' },
      { label: 'Tentative', value: 'MAYBE' },
    ];
    this.selectedGuest = 'YES';
  }

  ngOnInit(): void {
    this.huddleId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getHuddlePrimary();
    // this.getVehicles();
    this.tripType = [
      { name: 'Round', code: TripTypeEnum.R },
      { name: 'Depart', code: TripTypeEnum.D },
      { name: 'Return', code: TripTypeEnum.RT },
    ];
    this.carpoolFormGroup = this.formBuilder.group({
      vehicle: [null, Validators.required],
      tripType: [null, { disabled: true }],
      oneWayAllowed: [false],
      joinRequestRequired: [false],
      startTime: [null],
      returnTime: [null],
      notes: [''],
      startAddress: [null],
      endAddress: [null],
      externalCommChannelUrl: [''],
    });
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
          this.leaderName = `${user.firstName || ''} ${user.lastName || ''}`;
          this.profilePic = user.profilePicUrl;
        }),
      );
    this.userDetail$.subscribe();
  }

  getHuddlePrimary() {
    this.activeTab = {};
    this.tabs = [
      { label: 'Overview', key: 'overview' },
      { label: 'Participants', key: 'participants' },
      { label: 'Carpools', key: 'carpools' },
      { label: 'Expenses', key: 'expenses' },
      { label: 'Potluck', key: 'potluck' },
      { label: 'Messages', key: 'messages' },
      { label: 'Settings', key: 'settings' },
    ];
    this.huddleService.getHuddlePrimary(this.huddleId).subscribe({
      next: (data: any) => {
        const {
          startDateTime,
          endDateTime,
          rsvpStartDateTime,
          rsvpEndDateTime,
        } = data;
        const startDate = new Date(startDateTime);
        this.eventStartDateTime = startDateTime
          ? new Date(startDateTime)
          : null;
        this.eventEndDateTime = endDateTime ? new Date(endDateTime) : null;
        this.eventRsvpStartDateTime = rsvpStartDateTime
          ? new Date(rsvpStartDateTime)
          : null;
        this.eventRsvpEndDateTime = rsvpEndDateTime
          ? new Date(rsvpEndDateTime)
          : null;
        this.huddlePrimaryResponse = data;
        this.getSuggestedInvites(data.id);
        const imageUrl =
          environment.apiUrl + '/api/huddles/' + data.id + '/cover';
        if (data.coverPhotoCoordinate) {
          this.translateY = data.coverPhotoCoordinate?.ycoordinate;
        }
        if (data.coverPhotoUrl) {
          this.imagePipe
            .transform(imageUrl)
            .subscribe((image: string | null) => {
              this.coverPhotoUrl = image as string;
              this.storeCoverPhotoUrl = this.coverPhotoUrl;
              this.isCoverUploaded = true;
              this.isCroppedCover = true;
              this.isDraggingStart = true;
            });
        }

        if (data.selfHuddler != null) {
          this.interested = data.selfHuddler.interested;
          this.rsvpType = data.selfHuddler.rsvpType;
          if (
            this.rsvpType != null &&
            (this.rsvpType == 'YES' || this.rsvpType == 'MAYBE')
          ) {
            this.showInterestBtn = false;
          }
        }

        this.eventDate = startDate.getDate();
        this.eventLongDate = startDate;
        this.eventLongDateEnd = endDateTime ? new Date(endDateTime) : null;
        this.isPastHuddle = startDate < new Date();

        this.isHost = data.participation?.role === 'HOST';

        this.isHostOrCohost =
          data.participation?.role === 'HOST' ||
          data.participation?.role === 'CO_HOST';

        const { features } = data;
        this.isCarpoolAllowed = features.some(
          (u: any) => u.enabled && u.key === 'CARPOOLING',
        );
        this.expenseTracEnabled = features.some(
          (u: any) => u.enabled && u.key === 'EXPENSE_TRAC',
        );
        this.potluckPlanningEnabled = features.some(
          (u: any) => u.enabled && u.key === 'POTLUCK',
        );

        this.moreOptions.forEach((g: any) => {
          g.id === 'interested' && (g.disabled = !this.showInterestBtn);
          g.id === 'cancel' &&
            data?.participation.role !== 'HOST' &&
            (g.disabled = true);
        });
        this.moreOptions = this.moreOptions.filter((c: any) => {
          if (
            c.id === 'edit' &&
            this.huddlePrimaryResponse.participation?.role !== 'HOST' &&
            this.huddlePrimaryResponse.participation?.role !== 'CO_HOST'
          ) {
            return false;
          }

          if (
            c.id === 'edit' &&
            startDateTime && // Scheduled (allowing edit for not scheduled)
            (this.isPastHuddle || !this.isHostOrCohost) // Past or not host/co-host
          ) {
            return false;
          }
          return true;
        });
        !this.isCarpoolAllowed &&
          (this.moreOptions = this.moreOptions.filter(
            (more: any) => more.id !== 'carpool',
          ));
        this.settings = data.settings;
        this.settingDetails.privacy = data.privacy;
        this.settingDetails.setting = data.settings;
        this.settingDetails.getPaymentMethod = data.paymentMethod;
        this.settingDetails.refundPolicy = data.refundPolicy;
        this.settingDetails.features = features;
        data.settings.decisiveRsvp &&
          (this.goingOptions = this.goingOptions.filter(
            (going: any) => going.label !== 'Tentative',
          ));

        if (!this.isCarpoolAllowed) {
          this.tabs = this.tabs.filter((c) => c['key'] !== 'carpools');
        }
        if (!this.expenseTracEnabled) {
          this.tabs = this.tabs.filter((c) => c['key'] !== 'expenses');
        }
        if (!this.potluckPlanningEnabled) {
          this.tabs = this.tabs.filter((c) => c['key'] !== 'potluck');
        }

        // !data.settings.participantVisible &&
        //   !data.coHosts.some((user: User) => user.id === this.userInfo.id) &&
        //   (this.tabs = this.tabs.filter((tab) => tab.label !== 'Participants'));
        if (
          this.huddlePrimaryResponse.participation?.role !== 'HOST' &&
          this.huddlePrimaryResponse.participation?.role !== 'CO_HOST'
        ) {
          this.tabs = this.tabs.filter((c) => c['key'] !== 'settings');
        }
        if (
          !this.settings.participantVisible &&
          this.huddlePrimaryResponse.participation?.role !== 'HOST' &&
          this.huddlePrimaryResponse.participation?.role !== 'CO_HOST'
        ) {
          this.tabs = this.tabs.filter((c) => c['key'] !== 'participants');
        }
        if (
          (this.huddlePrimaryResponse.participation?.invited ||
            this.huddlePrimaryResponse.participation?.requested ||
            this.huddlePrimaryResponse.participation?.waiting ||
            this.huddlePrimaryResponse.participation?.interested) &&
          this.huddlePrimaryResponse.participation?.role !== 'HOST' &&
          this.huddlePrimaryResponse.participation?.role !== 'CO_HOST' &&
          this.huddlePrimaryResponse.participation?.role !== 'ATTENDEE' &&
          (this.huddlePrimaryResponse.privacy === 'PRIVATE' ||
            (this.huddlePrimaryResponse.privacy === 'COMMUNITY' &&
              this.huddlePrimaryResponse.communityObj?.privacy === 'PRIVATE'))
        ) {
          this.tabs = this.tabs.filter((c) => c['key'] === 'overview');
        }
        this.isLoading = false;
        setTimeout(() => {
          if (!this.activeTab?.['key']) this.activeTab = this.tabs[0];
        }, 100);
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Request to get huddle errored out.' + err.message);
        if (err.status === 403 || err.status === 404) {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }
      },
    });
  }

  receiveData(data: any) {
    this.huddleView = data.huddleView;
    this.settingDetails = { ...this.settingDetails, ...data.setting };
  }

  // getHuddle() {
  //   this.huddleService.getHuddle(this.huddleId).subscribe({
  //     next: (data: any) => {

  //       const { startDateTime, endDateTime } = data;
  //       const startDate = new Date(startDateTime);
  //       this.huddleView = data;

  //       this.coverPhotoUrl =
  //         environment.apiUrl + '/api/huddles/' + data.id + '/cover';
  //       if (data.selfHuddler != null) {
  //         this.interested = data.selfHuddler.interested;
  //         this.rsvpType = data.selfHuddler.rsvpType;
  //         if (
  //           this.rsvpType != null &&
  //           (this.rsvpType == 'YES' || this.rsvpType == 'MAYBE')
  //         ) {
  //           this.showInterestBtn = false;
  //         }
  //       }

  //       this.moreOptions.forEach((g: any) => {
  //         g.id === 'interested' && (g.disabled = !this.showInterestBtn);
  //         g.id === 'cancel' &&
  //           this.userInfo.id !== data.host?.id &&
  //           (g.disabled = true);
  //       });
  //       this.eventDate = startDate.getDate();
  //       this.eventLongDate = startDate;
  //       this.eventLongDateEnd = endDateTime ? new Date(endDateTime) : null;
  //       this.isPastHuddle = startDate < new Date();
  //       this.privacy = data.privacy;

  //       const { features } = data;
  //       this.isCarpoolAllowed = features.some(
  //         (u: any) => u.enabled && u.key === 'CARPOOLING',
  //       );
  //       this.expenseTracEnabled = features.some(
  //         (u: any) => u.enabled && u.key === 'EXPENSE_TRAC',
  //       );
  //       this.potluckPlanningEnabled = features.some(
  //         (u: any) => u.enabled && u.key === 'POTLUCK',
  //       );

  //       this.settings = data.settings;
  //       data.settings.decisiveRsvp &&
  //         (this.goingOptions = this.goingOptions.filter(
  //           (going: any) => going.label !== 'Tentative',
  //         ));
  //       !this.isCarpoolAllowed &&
  //         (this.moreOptions = this.moreOptions.filter(
  //           (more: any) => more.id !== 'carpool',
  //         ));

  //       !this.isCarpoolAllowed &&
  //         (this.tabs = this.tabs.filter((tab) => tab.label !== 'Carpools'));
  //       !this.expenseTracEnabled &&
  //         (this.tabs = this.tabs.filter((tab) => tab.label !== 'Expenses'));
  //       !this.potluckPlanningEnabled &&
  //         (this.tabs = this.tabs.filter((tab) => tab.label !== 'Potluck'));
  //       !data.settings.participantVisible &&
  //         !data.coHosts.some((user: User) => user.id === this.userInfo.id) &&
  //         (this.tabs = this.tabs.filter((tab) => tab.label !== 'Participants'));
  //       data.coHosts = data.coHosts || [];
  //       data.host && data.coHosts.push(data.host);
  //       this.huddleViewEvent.next(data);

  //       this.isLoading = false;
  //       this.viewCarpoolEvent.next(this.huddleView);
  //       if (this.huddleView.participation?.invited) {
  //         this.tabs = this.tabs.filter((tab) => tab.label === 'Overview');
  //       }

  //       setTimeout(() => {
  //         if (!this.activeTab?.label) this.activeTab = this.tabs[0];
  //       }, 100);
  //     },
  //     error: (err) => {
  //       this.isLoading = false;
  //       console.error('Request to get huddle errored out.' + err.message);
  //     },
  //   });
  // }

  onActiveItemChange(e: any) {
    if (this.prevTab?.label !== e?.label) {
      this.activeTab = e;
      this.prevTab = this.activeTab;
      if (e.label === 'Overview') {
        this.huddleViewEvent.next(this.huddleView);
      }
      if (e.label === 'Participants') {
        this.huddleParticipantEvent.next(e.label);
      }
    }
  }

  getVehicles() {
    this.vehicleService.getVehicles().subscribe({
      next: (data: any) => {
        this.vehicles = data;
      },
      error: (err) => {
        console.error('Request to get vehicles errored out.' + err.message);
      },
    });
  }

  openCarpoolDialog() {
    this.isCreate = true;
    this.isViewMode = false;
    this.dialogEvent.next({
      dialogType: 'CARPOOL',
      visible: true,
      isCreate: true,
      isViewMode: false,
      huddleCarpool: true,
      huddleObj: {
        id: this.huddleView.id,
        huddle: this.huddleView,
      },
    });
  }

  onStartAddressChange(e: any) {
    this.carpoolFormGroup.get('startAddress')?.patchValue(e);
    initMap('gmap-route').then((map) => {
      if (this.carpoolFormGroup.get('endAddress')?.value) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    });
  }

  onEndAddressChange(e: any) {
    this.carpoolFormGroup.get('endAddress')?.patchValue(e);
    initMap('gmap-route').then((map) => {
      if (this.carpoolFormGroup.get('startAddress')?.value) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    });
  }

  onTripTypeChange(e: any) {
    const { value } = e;
    const tripType = value.code;
    this.isRoundOrDepart =
      tripType === TripTypeEnum.D || tripType === TripTypeEnum.R;
    this.isReturn = tripType === TripTypeEnum.RT;
  }

  onSubmit() {
    const { tripType } = this.carpoolFormGroup.value;
    const carpool: Carpool = {
      ...this.carpoolFormGroup.value,
      parentEntity: 'HUDDLE',
      parentId: this.huddleId,
      tripType: tripType.code,
      seatingCapacity: 0,
      leader: {},
    };

    if (this.carpoolFormGroup.valid) {
      const method = this.isCreate ? 'createCarpool' : 'updateCarpool';
      this.submitted = true;
      this.huddleService[method](carpool).subscribe({
        next: (data: any) => {
          this.submitted = false;
          this.carpoolVisible = false;
          this.resetForm();
        },
        error: (err) => {
          this.submitted = false;
          console.error('Request to create carpool errored out.' + err.message);
        },
      });
    }
  }

  editCarpool(data: any) {
    this.canCreateCarpool = !data;
  }

  resetForm() {
    this.carpoolFormGroup.reset({
      vehicleName: null,
      tripType: null,
      startAddress: null,
      endAddress: null,
    });
    this.startAddressSubject.next({
      action: 'RESET',
    });
    this.endAddressSubject.next({
      action: 'RESET',
    });
  }

  calculateAndDisplayRoute(
    directionsService: google.maps.DirectionsService,
    directionsRenderer: google.maps.DirectionsRenderer,
  ) {
    const waypts: google.maps.DirectionsWaypoint[] = [];
    const _startAddress: string =
      this.carpoolFormGroup.get('startAddress')?.value.formattedAddress;
    const _endAddress: string =
      this.carpoolFormGroup.get('endAddress')?.value.formattedAddress;
    directionsService
      .route({
        origin: _startAddress,
        destination: _endAddress,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
      });
  }

  renderAddress() {
    this.startAddressSubject.next({
      action: 'FORM',
      payload: {
        startAddress: this.carpoolFormGroup.get('startAddress')?.value,
      },
    });
    this.endAddressSubject.next({
      action: 'FORM',
      payload: {
        endAddress: this.carpoolFormGroup.get('endAddress')?.value,
      },
    });
    this.onEndAddressChange(this.carpoolFormGroup.get('endAddress')?.value);
  }

  get f() {
    return this.carpoolFormGroup.controls;
  }

  generateUrlToShare() {}

  getUsersToInvite() {}

  inviteUsers() {}

  chooseNewHost() {
    this.userPickerVisible = true;
    this.userPickerEvent.next([]);
    this.userPickerEvent.next('huddleId_' + this.huddlePrimaryResponse.id);
    this.userPickerEvent.next(
      'communityId_' + this.huddlePrimaryResponse.communityId,
    );
    this.userPickerEvent.next('onLoadUsers');
    this.userPickerEvent.next(true);
  }

  closeDialog(): void {
    this.displayHostRsvpUpdateConfirmation = false;
  }

  saveRsvp(rsvpType: string) {
    if (
      this.eventRsvpStartDateTime &&
      new Date() < this.eventRsvpStartDateTime
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Huddle RSVP',
        detail: `RSVP has not started yet. You can confirm your RSVP from ${format(this.eventRsvpStartDateTime, 'eeee, MMMM dd yyyy hh:mm a')}, onwards.`,
      });
      return;
    }

    if (this.eventRsvpEndDateTime && new Date() > this.eventRsvpEndDateTime) {
      this.messageService.add({
        severity: 'error',
        summary: 'Huddle RSVP',
        detail: `RSVP has ended at ${format(this.eventRsvpEndDateTime, 'eeee, MMMM dd yyyy hh:mm a')}.`,
      });
      return;
    }

    if (
      this.huddlePrimaryResponse.participation?.role === 'HOST' &&
      (rsvpType === 'MAYBE' || rsvpType === 'NO')
    ) {
      this.displayHostRsvpUpdateConfirmation = true;
      return;
    }

    this.prevRsvpType = rsvpType;
    const {
      settings: { guestAllowed },
      paymentMode,
      paymentMethod,
      refundPolicy,
    } = this.huddleView;

    if ((guestAllowed || paymentMode === 'PAID') && rsvpType !== 'NO') {
      this.guestVisible = true;
      this.selectedGuest = rsvpType;
    } else if (
      this.isCarpoolAllowed &&
      this.canCreateCarpool &&
      rsvpType !== 'YES'
    )
      this.confirmationService.confirm({
        accept: () => {},
        reject: () => {},
      });
    else this.onRSVPClick(this.prevRsvpType, null);
  }

  onRSVPClick(rsvpType: string, guests: any) {
    this.rsvpDialog.reject();
    this.huddleService
      .rsvp({
        huddleId: this.getId(this.huddleId),
        rsvpType,
        ...(guests && { guests }),
      })
      .subscribe({
        next: (data: any) => {
          console.log('Request to RSVP the huddle is successful.');
          if (!data.error) {
            this.moreOptions.forEach((g: any) => {
              g.id === 'interested' && (g.disabled = !this.showInterestBtn);
            });
            this.messageService.add({
              severity: 'success',
              summary: 'Huddle RSVP',
              detail: data.message,
            });
            this.guestVisible = false;
            this.isLoading = false;
            this.getHuddlePrimary();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Huddle RSVP',
              detail: data.message,
            });
          }
        },
        error: (e) => {
          console.error('Request to RSVP the huddle  errored out.');
          console.error(e);
        },
        complete: () => console.info('Request to RSVP the huddle completed.'),
      });
  }

  saveInterest(interested: boolean) {
    this.huddleService
      .saveInterest(this.getId(this.huddleId), interested)
      .subscribe({
        next: (data: any) => {
          console.log('Request to join the huddle is successful.');
          if (!data.error) {
            this.interested = interested;
          }
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Interest',
            detail: data.message,
          });
        },
        error: (e) => {
          console.error('Request to join the huddle  errored out.');
          console.error(e);
        },
        complete: () => console.info('Request to join the huddle completed.'),
      });
  }

  onHuddleData(e: any) {
    this.viewCarpoolEvent.next(e);
  }

  getId(idStr: string | null) {
    let id = 0;
    if (idStr) {
      id = parseInt(idStr);
    }
    return id;
  }

  onClickCarpool() {
    this.activeTab = this.tabs.find((tab) => tab.label === 'Carpools');
  }

  onOverlayMenuClick(id: string) {
    this.menuId = id;
    if (id === 'copy_huddle_link') {
      this.shareLinkEvent.next(!0);
    } else if (id === 'interested') {
      this.saveInterest(!this.interested);
    } else if (id === 'carpool') {
      this.activeTab = this.tabs.find((tab) => tab.label === 'Carpools');
    } else if (id === 'cancel') {
      this.confirmationService.confirm({
        accept: () => {},
        reject: () => {},
      });
    } else if (id === 'invite') {
      this.inviteVisible = true;
    } else if (id === 'withdraw') {
      this.requestWithdraw();
    } else if (id === 'edit') {
      this.router.navigate(['huddle', 'edit', this.huddlePrimaryResponse.id]);
    } else if (id === 'invite-users') {
      this.showMoreInvite();
    }
  }

  onView(e: any) {
    this.canCreateCarpool = e.carpooling;
  }

  cancelHuddle() {
    this.huddleService.cancelHuddle('' + this.huddleView.id).subscribe({
      next: (data: any) => {
        this.router.navigate(['/huddle', 'huddles']);
        this.messageService.add({
          severity: 'success',
          summary: 'Cancel Huddle',
          detail: 'Huddle has been cancelled',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Cancel Huddle',
          detail: err.error.message,
        });
      },
    });
  }

  invite(e: any) {
    this.huddleService.invite('' + this.huddleId, e).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Invite Huddle',
          detail: data.message,
        });
        this.inviteVisible = false;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Huddle',
          detail: err.error.message,
        });
      },
    });
  }

  addGuest() {
    this.isLoading = true;
    const _guests: any = {};
    this.adult > 0 && (_guests['ADULT'] = this.adult);
    this.teens > 0 && (_guests['TEEN'] = this.teens);
    this.kids > 0 && (_guests['KID'] = this.kids);
    this.toddlers > 0 && (_guests['TODDLER'] = this.toddlers);
    this.seniors > 0 && (_guests['SENIOR'] = this.seniors);
    this.onRSVPClick(
      this.selectedGuest,
      Object.keys(_guests).length > 0 ? _guests : null,
    );
  }

  requestWithdraw() {
    this.huddleService
      .deleteRequestOrWaiting({
        huddleId: '' + this.huddleId,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Public',
            detail: data.message,
          });
          this.getHuddlePrimary();
        },
        error: (err) => this.handleCatch(err),
      });
  }

  handleCatch(err: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Huddle Error',
      detail: err.error.message || err.message,
    });
  }

  onHuddlers(data: any) {
    this.activeTab = this.tabs.find((tab) => tab.label === 'Participants');
    this.selectedButtonTab = data;
  }

  formatDate(startDate: Date, endDate: Date | null) {
    return formatEventDate(
      startDate.toISOString(),
      endDate?.toISOString() || '',
    );
  }

  onRsvpChange(data: { error: boolean; message: string; rsvpType: string }) {
    if (!data.error) {
      this.rsvpType = data.rsvpType;
      this.getHuddlePrimary();
    }
  }

  onSelectedUser(user: User) {
    this.selectedUser.push(user);
  }

  onRemoveUser(id: number) {
    this.selectedUser = this.selectedUser.filter((u: User) => u.id !== id);
  }
  onChooseUserAsHost() {
    this.huddleService
      .updateHost('' + this.huddleId, this.selectedUser[0].id)
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'New Host',
            detail: data.message,
          });
          this.getHuddlePrimary();
          this.userPickerVisible = false;
          this.displayHostRsvpUpdateConfirmation = false;
        },
        error: (err) => {
          this.handleCatch(err);
          this.userPickerVisible = true;
        },
      });
  }

  onCropperClose() {
    this.visible = false;
    if (this.editable) {
      this.editable = false;
    } else {
      this.isCroppedCover = !!this.coverPhotoUrl;
    }
  }

  zoomInAndOut(transform: string) {
    if (transform === 'in') this.scale += 0.1;
    else this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  onTieredMenuItemClick(action: string) {
    switch (action) {
      case 'image_delete':
        this.editAndRemoveCroppedImage('D');
        break;
      case 'image_upload':
        this.uploadRef.nativeElement.click();
        if (this.coverPhotoUrl) this.editable = true;
    }
  }

  blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  onCropperDialogShow() {
    this.scale = 1;
  }

  onMouseDown(event: MouseEvent): void {
    this.isDragging = true;
    this.lastMouseY = event.clientY;
    this.isDraggingStart = true;
    event.preventDefault();
  }

  onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      const deltaY = event.clientY - this.lastMouseY;

      this.updatePosition(deltaY);

      this.lastMouseY = event.clientY;
    }
  }

  onMouseUp(): void {
    this.isDragging = false;
  }

  @HostListener('window:mouseup', ['$event'])
  onWindowMouseUp(event: MouseEvent): void {
    if (this.isDragging) {
      this.onMouseUp();
    }
  }

  @HostListener('window:mousemove', ['$event'])
  onWindowMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      this.onMouseMove(event);
    }
  }

  updatePosition(deltaY: number) {
    const wrapper = this.imageRef.nativeElement;
    const img = wrapper.querySelector('img');
    const maxTranslateY = wrapper.clientHeight - img.clientHeight;
    this.translateY = Math.min(
      0,
      Math.max(this.translateY + deltaY, maxTranslateY),
    );
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length) {
      this.file = event.target.files[0];
      validateImage(this.file, imageConst.COVER)
        .then(() => {
          this.isCroppedCover = true;
          this.roundCropper = false;
          this.ratio = 16 / 9;
          this.imageChangedEvent = event;
          this.visible = true;
        })
        .catch((err) => {
          this.messageService.add(err);
        });
    }
  }

  onCancelUpload() {
    if (this.editable) {
      this.coverPhotoUrl = this.storeCoverPhotoUrl;
      this.isCoverUploaded = true;
      this.isCroppedCover = true;
      this.isDraggingStart = true;
      return;
    }
    this.coverPhotoUrl = null;
    this.isDraggingStart = false;
  }

  uploadImage(uploadType: number) {
    this.isUploading = true;
    imageCompression
      .compressImage(this.blob, this.imageCompress)
      .then((formData: any) => {
        if (uploadType === 1) {
          formData.append('y_coordinate', this.translateY);
          this.huddleService
            .updateHuddleCoverPhoto(this.huddleId, formData)
            .subscribe({
              next: (data: any) => {
                console.log('Request to update cover photo succeeded.');
                const imageUrl =
                  environment.apiUrl + '/api/huddles/' + data.id + '/cover';
                this.isUploading = false;
                this.visible = false;
                this.isCoverUploaded = true;
                this.editable = false;
                this.isDraggingStart = true;
              },
              error: (err) => {
                this.isUploading = false;
                this.messageService.add({
                  severity: 'error',
                  summary: 'Upload',
                  detail: err.error.message,
                });
              },
            });
        }
      });
  }

  editAndRemoveCroppedImage(action: string) {
    action === 'E' && (this.visible = true);
    if (action === 'D') {
      this.isRemoving = true;
      this.huddleService.removeHuddleCoverPhoto('' + this.huddleId).subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Delete Cover Photo',
            detail: data.message,
          });
          this.isCroppedCover &&
            ((this.croppedImage = null), (this.coverPhotoUrl = null));
          this.isRemoving = false;
          this.isCoverUploaded = false;
          this.isDraggingStart = false;
          this.editable = false;
          this.getHuddlePrimary();
        },
        error: (err) => {
          this.isRemoving = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Delete Cover Photo',
            detail: err.error.message,
          });
        },
      });
    }
  }

  profileFileChangeEvent(event: any): void {
    if (event.target.files.length) {
      this.file = event.target.files[0];
      validateImage(this.file, imageConst.PROFILE)
        .then(() => {
          this.imageChangedEvent = event;
          this.visible = true;
          this.isCroppedCover = false;
          this.isProfileCropppedCover = true;
          this.roundCropper = true;
          this.ratio = 1 / 1;
        })
        .catch((err) => {
          this.messageService.add(err);
        });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageCroppedEvent = event;
    if (this.isCroppedCover) {
      this.uploadType = 1;
      this.blob = event?.blob;
    } else {
      this.uploadType = 2;
      this.blob = event?.blob;
    }
  }

  onCropped() {
    const _blobUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.imageCroppedEvent.objectUrl as string,
    ) as string;
    if (this.uploadType === 1) {
      this.coverPhotoUrl = _blobUrl;
      if (this.editable) {
        this.isCoverUploaded = !this.isCoverUploaded;
        this.isDraggingStart = !this.isDraggingStart;
      }
    }
    this.visible = false;
  }

  enableEdit() {
    this.editable = true;
  }

  getSuggestedInvites(id: any) {
    this.huddleService.getPredictsInvitee('' + id, 0, 5).subscribe({
      next: (data: any) => {
        this.followings = data.content;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: err.message,
        });
        console.error(
          'Request to get suggested invites errored out.' + err.message,
        );
      },
    });
  }

  showMoreInvite() {
    this.userInvitePickerVisible = true;
    this.userPickerEvent.next([]);
    this.userPickerEvent.next('huddleId_' + this.huddlePrimaryResponse.id);
    this.userPickerEvent.next('onLoad');
    this.userPickerEvent.next(true);
  }

  onInvite(userId: number) {
    this.huddleService
      .invitePredict('' + this.huddlePrimaryResponse.id, {
        huddleId: Number(this.huddlePrimaryResponse.id),
        userId,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Invite',
            detail: data.message,
          });
          this.userPickerEvent.next('loaderStop');
          this.userPickerEvent.next('filterUser_' + userId);
          this.followings = this.followings.filter((u) => u.id !== userId);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: err.message,
          });
          console.error('Request to get invite errored out.' + err.message);
        },
      });
  }

  showInviteBtnMenu(itemId?: string): boolean {
    if (itemId) {
      const isInviteItem = itemId === 'invite-users' || itemId === 'invite';
      // Check for menu-based visibility
      if (isInviteItem) {
        const isHostOrCoHost =
          this.huddlePrimaryResponse?.participation?.role === 'HOST' ||
          this.huddlePrimaryResponse?.participation?.role === 'CO_HOST';

        const isAttendeeWithInvitationAllowed =
          this.huddlePrimaryResponse?.participation?.role === 'ATTENDEE' &&
          this.huddlePrimaryResponse?.settings?.invitationAllowed;

        if (this.isPastHuddle) {
          return false;
        }
        return isHostOrCoHost || isAttendeeWithInvitationAllowed;
      }
      return true;
    }

    // Check for div-based visibility
    const isHostOrCoHost =
      this.huddlePrimaryResponse?.participation?.role === 'HOST' ||
      this.huddlePrimaryResponse?.participation?.role === 'CO_HOST';

    const isAttendeeWithInvitationAllowed =
      this.huddlePrimaryResponse?.participation?.role === 'ATTENDEE' &&
      this.huddlePrimaryResponse?.settings?.invitationAllowed;

    return isHostOrCoHost || isAttendeeWithInvitationAllowed;
  }
}
