import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { PaymentMethodName } from 'src/app/main/utilities/paymentMethodHelper';
import { Label } from '../../services/huddle.types';

@Component({
  selector: 'app-view-settings',
  templateUrl: './huddle-view-settings.component.html',
})
export class HuddleViewSettingsComponent implements OnInit {
  settingsFormGroup!: FormGroup;

  isLoading = true;

  privacyOptions: {
    name: string;
    key: string;
    description: string;
    buttonFor: string;
  }[];

  paymentforEvents: Label[];

  paymentMethods: Label[];

  currencies: Label[];

  ageRanges: Label[];

  guestAllowedAges: string[] | Label[];

  submitted = false;

  isEditMode = false;

  huddleId: string | null;

  attendeeLimitChecked = false;

  isCarpoolAllowed = false;

  communityRadioButton = 'huddle';

  @Input() settingDetails: any;

  constructor(
    private formBuilder: FormBuilder,
    private huddleService: HuddleService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.createOptions();
  }

  ngOnInit(): void {
    this.huddleId = this.activatedRoute.snapshot.paramMap.get('id');
    this.createFormGroup();
    this.disableEnableFields();
    this.getSetting();
  }

  createFormGroup() {
    this.settingsFormGroup = this.formBuilder.group({
      privacy: [this.privacyOptions[0].key],
      paymentMode: [this.paymentforEvents[0].value],
      paymentMethod: [this.paymentMethods[0]],
      paymentCurrency: [this.currencies[0]],
      paymentAmount: [0],
      refundPolicy: [null],
      decisiveRsvp: [false],
      approvalRequired: [false],
      participantLimit: [0],
      participantVisible: [false],
      invitationAllowed: [false],
      guestAllowed: [false],
      guestLimit: [0],
      carpoolAllowed: [false],
    });
  }

  createOptions() {
    this.privacyOptions = [
      {
        name: 'Public',
        key: 'PUBLIC',
        buttonFor: 'huddle',
        description: `The huddle will be visible to anyone on MissionPeak, including people who are not directly connected to you. It will appear in searches on the platform, and anyone can view the huddle details, RSVP, and share it with others.`,
      },
      {
        name: 'Connection',
        key: 'CONNECTION',
        buttonFor: 'huddle',
        description:
          'The huddle will only be visible to people within your network or those you are connected to. Only your connections can view the huddle details, RSVP, and interact with the huddle.',
      },
      {
        name: 'Private',
        key: 'PRIVATE',
        buttonFor: 'huddle',
        description: `The huddle will only be visible to the people you invite. Huddle details are not shared publicly or with your connections. Only invited guests can view the huddle, RSVP, and participate.`,
      },
      {
        name: 'Community',
        key: 'COMMUNITY',
        buttonFor: 'community',
        description: `Privacy of huddles of community is controlled by the privacy setting of the community`,
      },
    ];

    this.paymentforEvents = [
      { name: 'Free Event', value: 'FREE' },
      { name: 'Paid Event', value: 'PAID' },
      // { name: 'Ticketed Event', value: 'TICKETED' }
    ];

    this.paymentMethods = PaymentMethodName.paymentMethods;

    this.currencies = [{ name: 'US$', value: 'USD' }];

    this.ageRanges = [
      { name: '0 to 4 years old', value: 'TODDLER' },
      { name: '4 to 12 years old', value: 'KID' },
      { name: '13 to 17 years old', value: 'TEEN' },
      { name: '18 to 65 years old', value: 'ADULT' },
      { name: '65+ years old', value: 'SENIOR' },
    ];
  }

  onSubmit() {}

  disableEnableFields(disable = true) {
    for (const v of Object.values(this.f)) {
      disable ? v.disable() : v.enable();
    }
  }

  edit() {
    this.disableEnableFields(!1);
    this.isEditMode = !0;
  }

  getSetting() {
    this.isCarpoolAllowed = this.settingDetails.features.some(
      (u: any) => u.enabled && u.key === 'CARPOOLING',
    );
    this.communityRadioButton = this.settingDetails.communityId
      ? 'community'
      : 'huddle';
    this.settingsFormGroup
      .get('privacy')
      ?.patchValue(this.settingDetails.privacy);
    this.settingsFormGroup
      .get('paymentMode')
      ?.patchValue(this.settingDetails.paymentMode);
    this.settingsFormGroup
      .get('paymentCurrency')
      ?.patchValue(
        this.currencies.find(
          (currency) => currency.value === this.settingDetails.paymentCurrency,
        ),
      );
    this.settingsFormGroup
      .get('paymentAmount')
      ?.patchValue(this.settingDetails.paymentAmount);

    this.settingsFormGroup
      .get('decisiveRsvp')
      ?.patchValue(this.settingDetails.setting.decisiveRsvp);
    this.settingsFormGroup
      .get('approvalRequired')
      ?.patchValue(this.settingDetails.setting.approvalRequired);
    this.settingsFormGroup
      .get('participantLimit')
      ?.patchValue(this.settingDetails.setting.participantLimit);
    this.settingsFormGroup
      .get('participantVisible')
      ?.patchValue(!this.settingDetails.setting.participantVisible);
    this.settingsFormGroup
      .get('invitationAllowed')
      ?.patchValue(this.settingDetails.setting.invitationAllowed);
    this.settingsFormGroup
      .get('guestAllowed')
      ?.patchValue(this.settingDetails.setting.guestAllowed);
    this.settingsFormGroup
      .get('guestLimit')
      ?.patchValue(this.settingDetails.setting.guestLimit);
    this.settingsFormGroup
      .get('carpoolAllowed')
      ?.patchValue(this.isCarpoolAllowed);
    const selectedMethod = this.paymentMethods.find((method) => {
      return method.value === this.settingDetails.getPaymentMethod;
    });
    this.settingsFormGroup
      .get('refundPolicy')
      ?.patchValue(this.settingDetails.refundPolicy);
    this.settingsFormGroup.get('paymentMethod')?.setValue(selectedMethod);
    this.guestAllowedAges = this.settingDetails.setting.guestAllowedAges;
    this.attendeeLimitChecked =
      this.settingDetails.setting.participantLimit > 0 ? true : false;
    this.isLoading = false;
  }

  get f() {
    return this.settingsFormGroup.controls;
  }
}
