import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  IconDefinition,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { HuddleService } from 'src/app/main/services/huddle.service';
import {
  DialogEvent,
  Huddle,
  HuddleDetailResponse,
  HuddlePrimaryResponse,
  Huddlers,
  User,
} from 'src/app/main/types/main.types';
import {
  activityMap,
  calculateDuration,
  initMap,
  paymentType,
  visibilityMap,
} from 'src/app/main/utilities';
import { PaymentMethodName } from 'src/app/main/utilities/paymentMethodHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-about',
  templateUrl: './huddle-view-about.component.html',
})
export class HuddleViewAboutComponent implements OnInit {
  userPickerVisible = false;
  faUser: IconDefinition = faUser;
  faUsers: IconDefinition = faUsers;
  coHosts: any = [];
  isLoading = false;
  rsvpedHuddlers: Huddlers[];
  totalGoing: number;
  totalNotGoing: number;
  totalMayBe: number;
  visible = false;
  showHuddleDesc = false;
  showFullVenueNote = false;
  showFullMeetingNote = false;
  showFullParkingNote = false;
  descriptionLength = 0;
  totalCarpoolers = 0;
  totalCarpools = 0;
  totalWaiting = 0;
  activityMap = activityMap;
  followings: User[] = [];
  suggestedInvites: User[] = [];
  moment = moment;
  isCarpoolAllowed: boolean | undefined = false;
  visibilityMap = visibilityMap;
  paymentMode = paymentType;
  @Input() huddleId: string | null;
  @Input() huddlePrimaryResponse: HuddlePrimaryResponse;
  @Input() events: Observable<Huddle>;
  @Output() view = new EventEmitter();
  @Output() huddlers = new EventEmitter();
  @Output() huddleDetailDataEvent = new EventEmitter();
  userPickerEvent: Subject<any> = new Subject<any>();
  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();
  settingDetails: any = {};
  huddleView: any;
  huddleDetailResponse: HuddleDetailResponse;
  huddleViewEvent: Subject<Huddle> = new Subject<Huddle>();
  viewCarpoolEvent: Subject<any> = new Subject<any>();
  constructor(
    private messageService: MessageService,
    private huddleService: HuddleService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.events.subscribe((data) => {
      this.huddleView = null;
      this.getHuddleDetails();
      const { features } = this.huddlePrimaryResponse;

      this.isCarpoolAllowed = features?.some(
        (u: any) => u.enabled && u.key === 'CARPOOLING',
      );

      if (this.isCarpoolAllowed) {
        this.getCarpools();
      }
      this.getSuggestedInvites();
    });
  }

  getHuddleDetails() {
    this.isLoading = true;
    this.huddleService.getHuddleDetails(this.huddleId).subscribe({
      next: (data: any) => {
        this.rsvpedHuddlers = data.rsvpedHuddlers;
        this.huddleDetailResponse = data;

        this.coHosts = data.coHosts;
        this.settingDetails.communityId = data.communityId;
        this.settingDetails.paymentMode = data.paymentMode;
        this.settingDetails.paymentMethod = data.paymenyMethod;
        this.settingDetails.paymentCurrency = data.paymentCurrency;
        this.settingDetails.paymentAmount = data.paymentAmount.toFixed(2);
        this.settingDetails.refundPolicy = data.refundPolicy;
        data.coHosts = data.coHosts || [];
        data.host && data.coHosts.push(data.host);
        this.huddleView = {
          ...this.huddlePrimaryResponse,
          ...this.huddleDetailResponse,
        };
        this.huddleViewEvent.next(this.huddleView);
        this.viewCarpoolEvent.next(this.huddleView);
        this.huddleDetailDataEvent.emit({
          huddleView: this.huddleView,
          setting: this.settingDetails,
        });
        const addresses: { label: string; address: string | undefined }[] = [];
        data.venue &&
          addresses.push({ label: 'V', address: data.venue.formattedAddress });
        data.parking &&
          addresses.push({
            label: 'P',
            address: data.parking.formattedAddress,
          });

        data.meetingPoint &&
          addresses.push({
            label: 'M',
            address: data.meetingPoint.formattedAddress,
          });
        !data.venue &&
          !data.meetingPoint &&
          !data.parking &&
          addresses.push({ label: '', address: 'New York, NY, USA' });
        initMap(
          'gmap-about',
          addresses as { label: string; address: string }[],
        );
        this.rsvpActions();
        this.isLoading = false;
        // setTimeout(() => {
        //   if (!this.activeTab?.label) this.activeTab = this.tabs[0];
        // }, 100);
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Request to get huddle errored out.' + err.message);
      },
    });
  }
  calculateDuration(startDate: string, endDate: string | undefined) {
    return calculateDuration(startDate, endDate);
  }

  getProfileImageById(id: number | undefined) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  getCarpools() {
    this.huddleService
      .getCarpools({ parentEntity: 'HUDDLE', parentId: this.huddleId })
      .subscribe({
        next: (data: any) => {
          const { totalCarpoolers, totalCarpools, totalWaiting } = data;
          this.totalCarpoolers = totalCarpoolers;
          this.totalCarpools = totalCarpools;
          this.totalWaiting = totalWaiting;
          this.view.emit(data);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: err.message,
          });
          console.error('Request to get carpools errored out.' + err.message);
        },
      });
  }

  rsvpActions() {
    this.totalGoing = this.rsvpedHuddlers.filter(
      (r: any) => r.rsvpType === 'YES',
    ).length;
    this.totalMayBe = this.rsvpedHuddlers.filter(
      (r: any) => r.rsvpType === 'MAYBE',
    ).length;
    this.totalNotGoing = this.rsvpedHuddlers.filter(
      (r: any) => r.rsvpType === 'NO',
    ).length;
  }

  getSuggestedInvites() {
    this.huddleService
      .getPredictsInvitee('' + this.huddlePrimaryResponse.id, 0, 5)
      .subscribe({
        next: (data: any) => {
          this.followings = data.content;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: err.message,
          });
          console.error(
            'Request to get suggested invites errored out.' + err.message,
          );
        },
      });
  }

  onInvite(userId: number) {
    this.huddleService
      .invitePredict('' + this.huddlePrimaryResponse.id, {
        huddleId: Number(this.huddlePrimaryResponse.id),
        userId,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Invite',
            detail: data.message,
          });
          this.userPickerEvent.next('loaderStop');
          this.userPickerEvent.next('filterUser_' + userId);
          this.followings = this.followings.filter((u) => u.id !== userId);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: err.message,
          });
          console.error('Request to get invite errored out.' + err.message);
        },
      });
  }

  showMore() {
    this.userPickerVisible = true;
    this.userPickerEvent.next([]);
    this.userPickerEvent.next('huddleId_' + this.huddlePrimaryResponse.id);
    this.userPickerEvent.next('onLoad');
    this.userPickerEvent.next(true);
  }

  getLogoUrl(id: string) {
    return environment.apiUrl + '/api/communities/' + id + '/logo';
  }

  responders(tab: string) {
    this.huddlers.emit(tab);
  }

  redirect(path: string[]) {
    this.router.navigate(path);
  }

  getPaymentMethodName(value: any) {
    return PaymentMethodName.getPaymentMethodName(value);
  }
}
