import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContextSliderComponent } from '../../layout/context-slider/context-slider.component';
import { HuddleCreate2Component } from './huddle-create2/huddle-create2.component';
import { HuddleDashboardComponent } from './huddle-dashboard/huddle-dashboard.component';
import { HuddleHuddlesComponent } from './huddle-huddles/huddle-huddles.component';
import { HuddleRoutingModule } from './huddle-routing.module';
import { HuddleUpcomingComponent } from './huddle-upcoming/huddle-upcoming.component';
import { HuddleViewParticipantsComponent } from './huddle-view/huddle-participants/huddle-participants.component';
import { HuddleViewAboutComponent } from './huddle-view/huddle-view-about/huddle-view-about.component';
import { HuddleViewCarpoolComponent } from './huddle-view/huddle-view-carpool/huddle-view-carpool.component';
import { HuddleViewSettingsComponent } from './huddle-view/huddle-view-settings/huddle-view-settings.component';
import { HuddleViewComponent } from './huddle-view/huddle-view.component';
import { HuddleComponent } from './huddle.component';

import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from 'src/app/shared/shared.module';
import { HuddleEditComponent } from './huddle-edit/huddle-edit.component';
import { HuddleViewPotluckComponent } from './huddle-view/huddle-view-potluck/huddle-view-potluck.component';

@NgModule({
  declarations: [
    HuddleDashboardComponent,
    HuddleUpcomingComponent,
    HuddleViewComponent,
    HuddleViewCarpoolComponent,
    HuddleViewAboutComponent,
    HuddleComponent,
    ContextSliderComponent,
    HuddleHuddlesComponent,
    HuddleCreate2Component,
    HuddleViewParticipantsComponent,
    HuddleViewSettingsComponent,
    HuddleViewPotluckComponent,
    HuddleEditComponent,
  ],
  imports: [
    CommonModule,
    HuddleRoutingModule,
    CardModule,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    DropdownModule,
    ProgressBarModule,
    TabMenuModule,
    FontAwesomeModule,
    ToastModule,
    CheckboxModule,
    CalendarModule,
    AvatarModule,
    MenuModule,
    AvatarGroupModule,
    DividerModule,
    TabViewModule,
    SharedModule,
    MatSidenavModule,
    ScrollPanelModule,
    AccordionModule,
    SidebarModule,
    ImageCropperModule,
    EditorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    InfiniteScrollModule,
    InputTextModule,
    InputTextareaModule,
    InputSwitchModule,
    ProgressSpinnerModule,
    TieredMenuModule,
    SkeletonModule,
    SelectButtonModule,
    MessagesModule,
  ],
})
export class HuddleModule {}
