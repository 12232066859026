<div class="surface-ground pb-4" #editHuddle>
  <div *ngIf="formInvalid" class="sticky top-20 z-50">
    <p-messages
      [(value)]="formValidationMessage"
      [enableService]="false"
      styleClass="version"
      [closable]="true"
    ></p-messages>
  </div>
  <div class="mx-auto max-w-[860px] px-2 py-2 md:px-0">
    <div
      class="linear max mx-auto mb-24 transition-all duration-500"
      *ngIf="!isLoading"
    >
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="container mb-8 mt-4 max-w-[860px]">
          <h2 class="h-level-2 mb-4">Edit Huddle</h2>
          <div class="mb-2 text-base">
            <span class="block leading-4">Basic Informaton</span>
            <p class="sub-title mt-1 leading-6">
              Provide the basic information for the huddle.
            </p>
          </div>
          <p-card>
            <div class="mb-6">
              <strong for="activity" class="form-title mb-1 block leading-6"
                >Activity</strong
              >
              <div class="mb-2 mt-2">
                <div class="activity flex flex-1 rounded-md border shadow-sm">
                  <p-dropdown
                    class="w-full"
                    formControlName="selectedActivity"
                    [options]="huddleActivity"
                    optionLabel="name"
                    [disabled]="true"
                  ></p-dropdown>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <strong for="eventName" class="form-title block leading-6"
                >Name <span class="text-red-400">*</span></strong
              >
              <div class="mt-2">
                <input
                  pInputText
                  formControlName="name"
                  type="text"
                  id="eventName"
                  [ngClass]="{
                    'border-red-300': f['name'].errors,
                  }"
                  class="block h-12 w-full rounded-md border px-2 py-1.5"
                />
                <div
                  *ngIf="f['name'].errors"
                  class="invalid-feedback text-red-500"
                >
                  <small *ngIf="f['name'].errors['required']"
                    >Name is required</small
                  >
                </div>
              </div>
            </div>

            <div class="mb-3">
              <strong class="form-title block leading-6">Description</strong>
              <div class="mt-2 flex max-w-full items-center gap-x-3">
                <p-editor
                  #editor
                  class="flex-1"
                  formControlName="description"
                  [style]="{ height: '320px', width: '100%' }"
                >
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <button
                        type="button"
                        class="ql-bold"
                        aria-label="Bold"
                      ></button>
                      <button
                        type="button"
                        class="ql-italic"
                        aria-label="Italic"
                      ></button>
                      <button
                        type="button"
                        class="ql-underline"
                        aria-label="Underline"
                      ></button>
                    </span>
                    <span class="ql-formats">
                      <button
                        type="button"
                        class="ql-list"
                        value="ordered"
                        aria-label="Ordered List"
                      ></button>
                      <button
                        type="button"
                        class="ql-list"
                        value="bullet"
                        aria-label="Bullet List"
                      ></button>
                    </span>
                    <span class="ql-formats">
                      <select
                        class="ql-align"
                        aria-label="Text Alignment"
                      ></select>
                    </span>
                    <span class="ql-formats">
                      <button
                        type="button"
                        class="ql-link"
                        aria-label="Link"
                      ></button>
                      <button
                        type="button"
                        class="ql-video"
                        aria-label="Video"
                      ></button>
                    </span>
                  </ng-template>
                </p-editor>
              </div>
            </div>
            <div class="mb-3">
              <small
                *ngIf="f['startDateTime'].errors"
                class="error-message"
              ></small>
              <app-datetime-picker
                formControlName="startDateTime"
                highLevelText="Start Date"
                [events]="eventStartDateTimeSubject.asObservable()"
                (dateTimeOutput)="onSelect($event, dateTime.EVENTSTART)"
                [dateTimeInput]="eventStartDateTime"
                [highLevelValidationMessage]="eventStartDateValidationMessage"
                [labelStrong]="true"
              >
              </app-datetime-picker>
            </div>
            <div class="mb-3" *ngIf="true">
              <small
                *ngIf="f['endDateTime'].errors"
                class="error-message"
              ></small>
              <app-datetime-picker
                formControlName="endDateTime"
                highLevelText="End Date"
                [events]="eventEndDateTimeSubject.asObservable()"
                (dateTimeOutput)="onSelect($event, dateTime.EVENTEND)"
                [dateTimeInput]="eventEndDateTime"
                [highLevelValidationMessage]="eventEndDateValidationMessage"
                [labelStrong]="true"
              >
              </app-datetime-picker>
            </div>
          </p-card>
        </div>

        <div class="container mb-8 max-w-[860px]" *ngIf="true">
          <div class="mb-2 text-base">
            <span class="block leading-4">Venue of the Huddle</span>
            <p class="sub-title mt-1 leading-6">
              Select or enter the address of Venue of the Huddle.
            </p>
          </div>
          <p-card>
            <div class="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6 md:col-span-6">
                <app-address
                  id="venue"
                  label="Venue Address"
                  [labelStrong]="true"
                  formControlName="venue"
                  (renderAddress)="renderAddress()"
                  [events]="venueAddressSubject.asObservable()"
                  (changeLocationEvent)="onChangeLocation(location.VENUE)"
                  (addressChange)="onAddressChange($event, location.VENUE)"
                ></app-address>
              </div>

              <div class="col-span-6 md:col-span-6">
                <strong for="venueNote" class="form-title block leading-6"
                  >Venue Notes</strong
                >
                <div class="mt-2">
                  <textarea
                    pInputTextarea
                    rows="5"
                    cols="30"
                    id="venueNote"
                    formControlName="venueNote"
                    class="block w-full rounded-md border px-2 py-1.5"
                  ></textarea>
                </div>
              </div>
            </div>
          </p-card>
        </div>

        <div class="container max-w-[860px] py-4">
          <div class="flex items-center justify-between">
            <span class="h-level-3 block leading-4">Advance Settings</span>
            <p-inputSwitch
              [(ngModel)]="checked"
              [ngModelOptions]="{ standalone: true }"
            ></p-inputSwitch>
          </div>
        </div>
        <div class="container mb-8 max-w-[860px]" *ngIf="true">
          <div class="mb-8" *ngIf="isActivityHiking && checked">
            <div class="flex justify-between">
              <div class="mb-2 text-base">
                <span class="block leading-4">RSVP Start and End Date</span>
                <p class="sub-title mt-1 leading-6">
                  Custom start and end date for the RSVP, if any
                </p>
              </div>
              <div class="flex items-center justify-between">
                <p-inputSwitch
                  [(ngModel)]="rsvpChecked"
                  [ngModelOptions]="{ standalone: true }"
                  styleClass="mp-checkbox-small"
                ></p-inputSwitch>
              </div>
            </div>
            <p-card *ngIf="rsvpChecked">
              <div class="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6 md:col-span-6">
                  <div class="col-span-6 md:col-span-6">
                    <small
                      *ngIf="f['rsvpStartDateTime'].errors"
                      class="error-message"
                    ></small>
                    <app-datetime-picker
                      formControlName="rsvpStartDateTime"
                      highLevelText="RSVP Start Time"
                      [events]="eventRsvpStartDateTimeSubject.asObservable()"
                      (dateTimeOutput)="onSelect($event, dateTime.RSVPSTART)"
                      [dateTimeInput]="rsvpStartDateTime"
                      [highLevelValidationMessage]="
                        eventRsvpStartDateValidationMessage
                      "
                      [labelStrong]="true"
                    >
                    </app-datetime-picker>
                  </div>
                </div>

                <div class="col-span-6 md:col-span-6">
                  <small
                    *ngIf="f['rsvpEndDateTime'].errors"
                    class="error-message"
                  ></small>
                  <app-datetime-picker
                    formControlName="rsvpEndDateTime"
                    highLevelText="RSVP End Time"
                    [events]="eventRsvpEndDateTimeSubject.asObservable()"
                    (dateTimeOutput)="onSelect($event, dateTime.RSVPEND)"
                    [dateTimeInput]="rsvpEndDateTime"
                    [highLevelValidationMessage]="
                      eventRsvpEndDateValidationMessage
                    "
                    [labelStrong]="true"
                  >
                  </app-datetime-picker>
                </div>
              </div>
            </p-card>
          </div>

          <div class="mb-8" *ngIf="isActivityHiking && checked">
            <div class="flex justify-between">
              <div class="mb-2 text-base">
                <span class="block leading-4">Meeting Point of the Huddle</span>
                <p class="sub-title mt-1 leading-6">
                  Select or enter the address of Meeting Point of the Huddle.
                </p>
              </div>
              <div class="flex items-center justify-between">
                <p-inputSwitch
                  [(ngModel)]="meetingPointChecked"
                  [ngModelOptions]="{ standalone: true }"
                  styleClass="mp-checkbox-small"
                ></p-inputSwitch>
              </div>
            </div>
            <p-card *ngIf="meetingPointChecked">
              <div class="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6 -mt-[16px] md:col-span-6">
                  <app-address
                    *ngIf="checked"
                    id="meeting"
                    label="Meeting Point Address"
                    [labelStrong]="true"
                    formControlName="meetingPoint"
                    (renderAddress)="renderAddress()"
                    [events]="meetingPointAddressSubject.asObservable()"
                    (changeLocationEvent)="onChangeLocation(location.MEETING)"
                    (addressChange)="onAddressChange($event, location.MEETING)"
                  ></app-address>
                </div>

                <div class="col-span-6 md:col-span-6">
                  <strong
                    for="meeting_point_note"
                    class="form-title block leading-6"
                    >Meeting Point Notes</strong
                  >
                  <div class="mt-2">
                    <textarea
                      pInputTextarea
                      rows="5"
                      cols="30"
                      id="meeting_point_note"
                      formControlName="meetingPointNote"
                      class="block w-full rounded-md border px-2 py-1.5"
                    ></textarea>
                  </div>
                </div>
              </div>
            </p-card>
          </div>

          <div class="mb-8" *ngIf="isActivityHiking && checked">
            <div class="flex justify-between">
              <div class="mb-2 text-base">
                <span class="block leading-4"
                  >Parking Location of the Huddle</span
                >
                <p class="sub-title mt-1 leading-6">
                  Select or enter the address of Parking Location of the Huddle.
                </p>
              </div>
              <div class="flex items-center justify-between">
                <p-inputSwitch
                  [(ngModel)]="parkingPointChecked"
                  [ngModelOptions]="{ standalone: true }"
                  styleClass="mp-checkbox-small"
                ></p-inputSwitch>
              </div>
            </div>
            <p-card *ngIf="parkingPointChecked">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6 -mt-[16px] md:col-span-6">
                  <app-address
                    *ngIf="checked"
                    label="Parking Location Address"
                    id="parking"
                    [labelStrong]="true"
                    formControlName="parking"
                    (renderAddress)="renderAddress()"
                    [events]="parkingAddressSubject.asObservable()"
                    (changeLocationEvent)="onChangeLocation(location.PARKING)"
                    (addressChange)="onAddressChange($event, location.PARKING)"
                  ></app-address>
                </div>
                <div class="col-span-6 md:col-span-6">
                  <strong for="parking_note" class="form-title block leading-6"
                    >Parking Location Notes</strong
                  >
                  <div class="mt-2">
                    <textarea
                      pInputTextarea
                      rows="5"
                      cols="30"
                      id="parking_note"
                      formControlName="parkingNote"
                      class="block w-full rounded-md border px-2 py-1.5"
                    ></textarea>
                  </div>
                </div>
              </div>
            </p-card>
          </div>

          <div class="mb-8" *ngIf="checked">
            <div class="flex justify-between">
              <div class="mb-2 text-base">
                <span class="block leading-4">External URLs</span>
                <p class="sub-title mt-1 leading-6">
                  Enter external URLs if any.
                </p>
              </div>
              <div class="flex items-center justify-between">
                <p-inputSwitch
                  [(ngModel)]="externalUrlChecked"
                  [ngModelOptions]="{ standalone: true }"
                  styleClass="mp-checkbox-small"
                ></p-inputSwitch>
              </div>
            </div>
            <p-card *ngIf="externalUrlChecked">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6 mb-3 md:col-span-6">
                  <strong
                    for="trail_url"
                    class="form-title mb-1 block leading-6"
                    >Trail URL</strong
                  >
                  <input
                    pInputText
                    type="text"
                    id="trail_url"
                    formControlName="externalTrailUrl"
                    placeholder="https://www.alltrails.com/trail/us/california/mission-peak-loop-from-stanford-avenue-staging-area"
                    class="block h-12 w-full rounded-md border px-2 py-1.5"
                    [ngClass]="{
                      'border-red-500':
                        formGroup
                          .get('externalTrailUrl')
                          ?.hasError('pattern') &&
                        formGroup.get('externalTrailUrl')?.touched,
                    }"
                  />
                  <div
                    *ngIf="
                      formGroup.get('externalTrailUrl')?.hasError('pattern') &&
                      formGroup.get('externalTrailUrl')?.touched
                    "
                    class="text-sm text-red-500"
                  >
                    Please enter a valid URL.
                  </div>
                </div>
                <div class="col-span-6 mb-3 md:col-span-6">
                  <strong
                    for="event_url"
                    class="form-title mb-1 block leading-6"
                    >Event URL</strong
                  >
                  <input
                    pInputText
                    type="text"
                    id="event_url"
                    formControlName="externalEventUrl"
                    placeholder="https://www.facebook.com/events/391199166920573/391199173587239"
                    class="block h-12 w-full rounded-md border px-2 py-1.5"
                    [ngClass]="{
                      'border-red-500':
                        formGroup
                          .get('externalEventUrl')
                          ?.hasError('pattern') &&
                        formGroup.get('externalEventUrl')?.touched,
                    }"
                  />
                  <div
                    *ngIf="
                      formGroup.get('externalEventUrl')?.hasError('pattern') &&
                      formGroup.get('externalEventUrl')?.touched
                    "
                    class="text-sm text-red-500"
                  >
                    Please enter a valid URL.
                  </div>
                </div>
                <div class="col-span-6 mb-3 md:col-span-6">
                  <strong
                    for="album_url"
                    class="form-title mb-1 block leading-6"
                    >Photo Album URL</strong
                  >
                  <input
                    pInputText
                    id="album_url"
                    type="text"
                    formControlName="externalPhotoAlbumUrl"
                    placeholder="https://photos.app.goo.gl/xd6eTFFH8Kb5mxfM6"
                    class="block h-12 w-full rounded-md border px-2 py-1.5"
                    [ngClass]="{
                      'border-red-500':
                        formGroup
                          .get('externalPhotoAlbumUrl')
                          ?.hasError('pattern') &&
                        formGroup.get('externalPhotoAlbumUrl')?.touched,
                    }"
                  />
                  <div
                    *ngIf="
                      formGroup
                        .get('externalPhotoAlbumUrl')
                        ?.hasError('pattern') &&
                      formGroup.get('externalPhotoAlbumUrl')?.touched
                    "
                    class="text-sm text-red-500"
                  >
                    Please enter a valid URL.
                  </div>
                </div>
                <div class="col-span-6 md:col-span-6">
                  <strong
                    for="comm_channel"
                    class="form-title mb-1 block leading-6"
                    >Communication Channel URL</strong
                  >
                  <input
                    pInputText
                    type="text"
                    id="comm_channel"
                    formControlName="externalCommChannelUrl"
                    placeholder="https://chat.whatsapp.com/H86M3sqR6FU50v0ADIclFL"
                    class="block h-12 w-full rounded-md border px-2 py-1.5"
                    [ngClass]="{
                      'border-red-500':
                        formGroup
                          .get('externalCommChannelUrl')
                          ?.hasError('pattern') &&
                        formGroup.get('externalCommChannelUrl')?.touched,
                    }"
                  />
                  <div
                    *ngIf="
                      formGroup
                        .get('externalCommChannelUrl')
                        ?.hasError('pattern') &&
                      formGroup.get('externalCommChannelUrl')?.touched
                    "
                    class="text-sm text-red-500"
                  >
                    Please enter a valid URL.
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </div>
        <div class="my-6 flex max-w-[860px] justify-between gap-4">
          <button
            type="button"
            pButton
            (click)="back()"
            class="mp-button mp-button-outlined rounded-md px-5 py-2 leading-6 shadow-sm"
          >
            Cancel
          </button>
          <div class="">
            <button
              type="button"
              pButton
              (click)="reset()"
              class="mp-button mp-button-outlined mr-2 rounded-md px-5 py-2 leading-6 shadow-sm"
            >
              Reset
            </button>
            <button
              type="submit"
              pButton
              class="mp-button mp-button-filled rounded-md px-5 py-2 leading-6 shadow-sm"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<app-loading *ngIf="isSaving"></app-loading>
