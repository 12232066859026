import { Location as LocationCommon, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { Editor, EditorInitEvent } from 'primeng/editor';
import { Subject } from 'rxjs';
import {
  HuddleUpdateRequest,
  PatchOperation,
  PatchOperationCode,
} from 'src/app/main/types/main.types';
import { urlPattern } from 'src/app/main/utilities/regexHelper';
import { DatetimePickerComponent } from 'src/app/shared/datetime-picker/datetime-picker.component';
import { HuddleService } from '../../../services/huddle.service';
import { Label } from '../services/huddle.types';
enum SOURCE {
  GOOGLE = 'GOOGLE',
  MANUAL = 'MANUAL',
}

enum LOCATION {
  VENUE = 'V',
  MEETING = 'M',
  PARKING = 'P',
}

enum DATETIME {
  EVENTSTART = 'ES',
  EVENTEND = 'EE',
  RSVPSTART = 'RS',
  RSVPEND = 'RE',
}

@Component({
  selector: 'app-huddle-edit',
  templateUrl: './huddle-edit.component.html',
})
export class HuddleEditComponent implements OnInit {
  isBrowser = true;
  huddleActivity: any[] = [];
  formGroup!: FormGroup;
  location = LOCATION;
  dateTime = DATETIME;
  checked = false;
  rsvpChecked = false;
  meetingPointChecked = false;
  parkingPointChecked = false;
  externalUrlChecked = false;
  required = false;
  huddleId: any;
  venueAddressSubject: Subject<any> = new Subject<any>();
  parkingAddressSubject: Subject<any> = new Subject<any>();
  meetingPointAddressSubject: Subject<any> = new Subject<any>();
  eventStartDateTimeSubject: Subject<any> = new Subject<any>();
  eventEndDateTimeSubject: Subject<any> = new Subject<any>();
  eventRsvpStartDateTimeSubject: Subject<any> = new Subject<any>();
  eventRsvpEndDateTimeSubject: Subject<any> = new Subject<any>();
  selectEndDateTime: Label;
  isActivityHiking = false;
  childData: any = {}; // Store child data
  originalData: HuddleUpdateRequest; // Store the original data fetched from server
  modifiedData = {} as Partial<HuddleUpdateRequest>; // Store the modified data
  formGroupValues: any;
  eventStartDateTime: Date | null;
  eventEndDateTime: Date | null;
  rsvpStartDateTime: Date | null;
  rsvpEndDateTime: Date | null;
  @ViewChild(DatetimePickerComponent)
  datetimePickerComponent!: DatetimePickerComponent;
  @ViewChild('editor') editorComponent!: Editor;
  eventStartDateValidationMessage: string;
  eventEndDateValidationMessage: string;
  eventRsvpStartDateValidationMessage: string;
  eventRsvpEndDateValidationMessage: string;
  eventStartDateTimeChanged = false;
  eventEndDateTimeChanged = false;
  eventRsvpStartDateTimeChanged = false;
  eventRsvpEndDateTimeChanged = false;
  isDateFormValid = true;
  isLoading = false;
  isSaving = false;
  formInvalid = false;
  formValidationMessage: Message[];
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private huddleService: HuddleService,
    private changeDetectorRef: ChangeDetectorRef,
    private _location: LocationCommon,
    private messageService: MessageService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.huddleId = this.activatedRoute.snapshot.paramMap.get('id');
    this.huddleActivity = [
      { name: 'General', value: 'GENERIC' },
      { name: 'Hiking', value: 'HIKING' },
    ];
    this.formGroup = this.formBuilder.group({
      selectedActivity: [],
      name: ['', Validators.required],
      description: [null],
      startDateTime: [null],
      endDateTime: [null],
      venue: [null],
      venueNote: [null],
      parking: [null],
      parkingNote: [null],
      meetingPoint: [null],
      meetingPointNote: [null],
      rsvpStartDateTime: [null],
      rsvpEndDateTime: [null],
      externalTrailUrl: [null, Validators.pattern(urlPattern)],
      externalEventUrl: [null, Validators.pattern(urlPattern)],
      externalPhotoAlbumUrl: [null, Validators.pattern(urlPattern)],
      externalCommChannelUrl: [null, Validators.pattern(urlPattern)],
    });
    this.isLoading = true;
    this.getHuddleDetails();
  }

  getHuddleDetails() {
    this.huddleService.getHuddleDetails(this.huddleId).subscribe({
      next: (data: any) => {
        this.originalData = { ...data };
        this.huddleActivity = this.huddleActivity.filter(
          (act: any) => act.value === data.activity,
        );
        this.formGroup
          .get('selectedActivity')
          ?.patchValue(this.huddleActivity[0]);
        this.eventStartDateTime = data?.startDateTime
          ? new Date(data.startDateTime)
          : null;
        this.eventEndDateTime = data?.endDateTime
          ? new Date(data.endDateTime)
          : null;
        this.rsvpStartDateTime = data?.rsvpStartDateTime
          ? new Date(data.rsvpStartDateTime)
          : null;
        this.rsvpEndDateTime = data?.rsvpEndDateTime
          ? new Date(data.rsvpEndDateTime)
          : null;
        this.formGroup.patchValue(this.originalData);
        if (
          data.externalTrailUrl ||
          data.externalEventUrl ||
          data.externalPhotoAlbumUrl ||
          data.externalCommChannelUrl
        ) {
          this.checked = true;
          this.externalUrlChecked = true;
        }
        if (data.activity === 'HIKING') {
          this.isActivityHiking = true;
          if (data.rsvpStartDateTime || data.rsvpEndDateTime) {
            this.checked = true;
            this.rsvpChecked = true;
          }
          if (data.parking || data.parkingNote) {
            this.checked = true;
            this.parkingPointChecked = true;
          }
          if (data.meetingPoint || data.meetingPointNote) {
            this.checked = true;
            this.meetingPointChecked = true;
          }
        }
        this.isLoading = false;
        this.renderAddress();
      },
      error: (err) => {
        console.error('Request to get huddle errored out.' + err.message);
        this.isLoading = false;
      },
    });
  }

  updateHuddle(modifiedFormValues: any, originalValues: any) {
    const patchOps: PatchOperation[] = [];
    Object.keys(modifiedFormValues).forEach((key) => {
      const newValue = modifiedFormValues[key];
      const oldValue = originalValues[key];

      if (newValue !== oldValue) {
        const patchOp: PatchOperation = {
          field: key,
          operation:
            newValue === null
              ? PatchOperationCode.DELETE
              : PatchOperationCode.UPDATE,
        };

        patchOps.push(patchOp);
      }
    });

    if (patchOps.length === 0) {
      this.isSaving = false;
      this.messageService.add({
        severity: 'warn',
        summary: 'Huddle Update',
        detail: 'Nothing for Update',
      });
      return;
    }
    const requestBody: any = {
      id: this.huddleId,
      patchOps: patchOps,
      ...modifiedFormValues,
    };

    this.huddleService.updateHuddle(this.huddleId, requestBody).subscribe({
      next: (response: any) => {
        console.log('Update successful', response);
        this.isSaving = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Huddle Update',
          detail: response.message,
        });
        this.router.navigate(['huddle', 'view', this.huddleId]);
      },
      error: (err: any) => {
        console.error('Update failed', err);
        this.isSaving = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Huddle Update',
          detail: err.error.message,
        });
      },
    });
  }

  onAddressChange(e: any, addressType: string) {
    const prop =
      addressType === LOCATION.VENUE
        ? 'venue'
        : addressType === LOCATION.MEETING
          ? 'meetingPoint'
          : 'parking';
    if (prop === 'venue') {
      this.formGroup.get('venue')?.patchValue(e);
    }
    if (prop === 'meetingPoint') {
      this.formGroup.get('meetingPoint')?.patchValue(e);
    }
    if (prop === 'parking') {
      this.formGroup.get('parking')?.patchValue(e);
    }
  }

  onChangeLocation(addressType: string) {}

  renderAddress() {
    if (this.originalData?.venue) {
      this.venueAddressSubject.next({
        action: 'FORM',
        payload: {
          pointAddress: this.formGroup.get('venue')?.value,
        },
      });
    }
    if (this.originalData?.parking) {
      this.parkingAddressSubject.next({
        action: 'FORM',
        payload: {
          pointAddress: this.formGroup.get('parking')?.value,
        },
      });
    }
    if (this.originalData?.meetingPoint) {
      this.meetingPointAddressSubject.next({
        action: 'FORM',
        payload: {
          pointAddress: this.formGroup.get('meetingPoint')?.value,
        },
      });
    }
  }
  resetDateTime() {
    if (this.originalData?.startDateTime) {
      this.eventStartDateTimeSubject.next({
        action: 'RESET',
      });
    }
    if (this.originalData?.endDateTime) {
      this.eventEndDateTimeSubject.next({
        action: 'RESET',
      });
    }
    if (this.originalData?.rsvpStartDateTime) {
      this.eventRsvpStartDateTimeSubject.next({
        action: 'RESET',
      });
    }
    if (this.originalData?.rsvpEndDateTime) {
      this.eventRsvpEndDateTimeSubject.next({
        action: 'RESET',
      });
    }
  }

  async onEditorInit(e: EditorInitEvent) {
    if (this.isBrowser) {
      const { Delta } = await import('quill/core');
      e.editor.clipboard.addMatcher(
        Node.ELEMENT_NODE,
        function (node: HTMLElement) {
          return new Delta().insert(node.innerText);
        },
      );
    }
  }

  onSelect(datetimeData: any, type: string) {
    const prop =
      type === DATETIME.EVENTSTART
        ? 'eventStartDateTime'
        : type === DATETIME.EVENTEND
          ? 'eventEndDateTime'
          : type === DATETIME.RSVPSTART
            ? 'rsvpStart'
            : 'rsvpEnd';
    let modifiedDateTime;
    if (datetimeData.valid) {
      this.isDateFormValid = datetimeData.valid;
      modifiedDateTime = datetimeData.dateObject
        ? datetimeData.dateObject.toISOString()
        : null;
    } else {
      this.isDateFormValid = datetimeData.valid;
      modifiedDateTime = datetimeData.dateObject;
    }
    if (prop === 'eventStartDateTime') {
      this.eventStartDateTimeChanged = true;
      this.formGroup.get('startDateTime')?.patchValue(modifiedDateTime);
    }
    if (prop === 'eventEndDateTime') {
      this.eventEndDateTimeChanged = true;
      this.formGroup.get('endDateTime')?.patchValue(modifiedDateTime);
    }
    if (prop === 'rsvpStart') {
      this.eventRsvpStartDateTimeChanged = true;
      this.formGroup.get('rsvpStartDateTime')?.patchValue(modifiedDateTime);
    }
    if (prop === 'rsvpEnd') {
      this.eventRsvpEndDateTimeChanged = true;
      this.formGroup.get('rsvpEndDateTime')?.patchValue(modifiedDateTime);
    }
  }

  validateHuddleUpdate(): boolean {
    const now = new Date();
    this.eventStartDateValidationMessage = '';
    this.eventEndDateValidationMessage = '';
    this.eventRsvpStartDateValidationMessage = '';
    this.eventRsvpEndDateValidationMessage = '';
    if (this.modifiedData?.startDateTime) {
      if (new Date(this.modifiedData?.startDateTime) < now) {
        this.formGroup.get('startDateTime')?.setErrors({ required: true });
        this.eventStartDateValidationMessage = new String(
          'Start date time cannot be in the past.',
        ) as string;
        return false;
      }
    }

    if (this.modifiedData?.endDateTime) {
      const start = this.modifiedData?.startDateTime
        ? new Date(this.modifiedData?.startDateTime)
        : null;

      if (start && new Date(this.modifiedData?.endDateTime) <= start) {
        this.formGroup.get('endDateTime')?.setErrors({ required: true });
        this.eventEndDateValidationMessage = new String(
          'End date time must be after Start date time.',
        ) as string;
        return false;
      }
    }

    if (
      this.modifiedData?.rsvpStartDateTime &&
      this.eventRsvpStartDateTimeChanged
    ) {
      const start = this.modifiedData?.startDateTime
        ? new Date(this.modifiedData?.startDateTime)
        : null;

      if (new Date(this.modifiedData?.rsvpStartDateTime) < now) {
        this.formGroup.get('rsvpStartDateTime')?.setErrors({ required: true });
        this.eventRsvpStartDateValidationMessage = new String(
          'RSVP Start Date cannot be in the past.',
        ) as string;
        return false;
      }

      if (start && new Date(this.modifiedData?.rsvpStartDateTime) > start) {
        this.formGroup.get('rsvpStartDateTime')?.setErrors({ required: true });
        this.eventRsvpStartDateValidationMessage = new String(
          'RSVP Start Date cannot be after Start Date.',
        ) as string;
        return false;
      }
    }

    if (
      this.modifiedData?.rsvpEndDateTime &&
      this.eventRsvpEndDateTimeChanged
    ) {
      const rsvpStart = this.modifiedData?.rsvpStartDateTime
        ? new Date(this.modifiedData?.rsvpStartDateTime)
        : null;
      const start = this.modifiedData?.startDateTime
        ? new Date(this.modifiedData?.startDateTime)
        : null;

      if (
        rsvpStart &&
        new Date(this.modifiedData?.rsvpEndDateTime) < rsvpStart
      ) {
        this.formGroup.get('rsvpEndDateTime')?.setErrors({ required: true });
        this.eventRsvpEndDateValidationMessage = new String(
          'RSVP End Date cannot be before RSVP Start Date.',
        ) as string;
        return false;
      }

      if (start && new Date(this.modifiedData?.rsvpEndDateTime) > start) {
        this.formGroup.get('rsvpEndDateTime')?.setErrors({ required: true });
        this.eventRsvpEndDateValidationMessage = new String(
          'RSVP End Date cannot be after Start Date.',
        ) as string;
        return false;
      }
    }

    return true;
  }

  setValidationMessage() {
    this.formInvalid = true;
    this.formValidationMessage = [
      {
        severity: 'error',
        detail:
          'Some fields contain invalid or missing information. Please review the form and correct any highlighted errors before proceeding.',
      },
    ];
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.setValidationMessage();
      return;
    }
    if (this.formGroup.get('name')?.value === '') {
      this.formGroup.get('name')?.setErrors({ required: true });
      return;
    }
    if (!this.isDateFormValid) {
      this.setValidationMessage();
      return;
    }

    this.formGroupValues = this.formGroup.value;
    Object.keys(this.formGroupValues).forEach((key) => {
      if (key !== 'selectedActivity') {
        let newValue = this.formGroupValues[key];
        if (typeof newValue === 'string' && newValue !== '') {
          newValue = newValue.trim();
        }
        if (newValue === '') {
          newValue = null;
          this.modifiedData[key as keyof HuddleUpdateRequest] = newValue;
        } else {
          this.modifiedData[key as keyof HuddleUpdateRequest] = newValue;
        }
      }
    });
    if (!this.validateHuddleUpdate()) {
      this.setValidationMessage();
      return;
    }
    this.isSaving = true;
    this.updateHuddle(this.modifiedData, this.originalData);
  }

  back() {
    this._location.back();
  }

  reset() {
    this.formGroup.patchValue(this.originalData);
    this.renderAddress();
    this.resetDateTime();
  }

  get f() {
    return this.formGroup.controls;
  }
}
